import config from 'config'
import { getUnixTime, parseISO } from 'date-fns'

import { msPerWeek } from 'utils/time'

export const ROLES = {
  ASSET_BUILDER: 'asset_builder',
  ASSET_INSTALLER: 'asset_installer',
  HUB_MANAGER: 'hub_manager',
  HUB_OPS: 'hub_ops',
  HUB_SKU_DETAILS: 'hub_sku_details',
  HUB_SKU_PRICES: 'hub_sku_prices',
  ORG_ADMIN: 'org_admin',
  ORG_MEMBER: 'org_member',
  ORG_OWNER: 'org_owner'
}

export function defaultUser() {
  return {
    id: '',
    settings: {},
    handle: '',
    name: '',
    verified: false,
    emails: [],
    data: [],
    factors: [],
    orgMemberships: [],
    passwordExpiresSoon: false,
    isMemberOfActiveOrg: false,
    ...normalizeUserParam('data', []),
    ...normalizeUserParam('emails', []),
    subscriptions: [],
    unreadThreads: 0,
    authStatus: 'unknown',
    access_token: undefined,
    access_token_expires: 0,
    validation_token: undefined,
    can: (x) => false,
    canAny: (x) => false,
    is: (x) => false,
    isAny: (x) => false,
    age: 0,
    isIdentified: false,
    isAuthN: false
  }
}

/*
Three states:

  0 = auth required && auth failed
  1 = auth required && auth succeeded
  -1 = auth not required so we're all good
*/

export function authZ(user, { action, actions, role, roles }) {
  if (action || actions || role || roles) {
    if (
      (action && !user.can(action)) ||
      (actions && !user.canAny(actions)) ||
      (role && !user.is(role)) ||
      (roles && !user.isAny(roles))
    ) {
      return 0
    }
    return 1
  }
  return -1
}

export function isAuthZ(user, opts) {
  return !!authZ(user, opts)
}

export function willPasswordExpireSoon(factors) {
  if (!Array.isArray(factors)) return false

  const now = Date.now()

  const passwords = factors
    .map((pw) => ({ ...pw, expiresIn: pw.expiresAt * 1000 - now }))
    .filter((pw) => pw.type === 'password' && pw.expiresIn > 0)

  const expiresSoon = passwords.some(({ expiresIn }) => expiresIn < msPerWeek)
  const expiresLater = passwords.some(({ expiresIn }) => expiresIn > msPerWeek)

  return expiresSoon && !expiresLater
}

// input 'key' and value, always return new dictionary values that
// are to be merged into parent dictionary
export function normalizeUserParam(key, value) {
  switch (key) {
    case 'data': {
      // TODO:
      // * switch address to 'location' or 'locale'
      // * switch profile to ... something else
      const data = {
        dataTypes: {
          profile: { value: {} },
          address: { value: {} },
          toggles: { value: {} },
          roles: { value: {} },
          skills: { value: {} },
          ...(value || []).reduce((a, d) => {
            a[d.type] = d
            return a
          }, {})
        }
      }
      data.toggles = data.dataTypes.toggles.value
      return data
    }

    case 'emails':
      if (value && value.length > 0) {
        const vemails = value.filter((e) => e.verified)
        const verified = vemails.length > 0
        const primaryEmail =
          value.find((email) => email.primary) || vemails[0] || value[0]
        return { verified, primaryEmail }
      }
      return {}

    default:
      return { [key]: value }
  }
}

export function normalizeUser(indata, real) {
  let roles = new Set()
  let actions = new Set()

  if (indata.access) {
    if (indata.access.roles) roles = new Set(indata.access.roles)
    if (indata.access.actions) actions = new Set(indata.access.actions)
  }

  const authStatus = (indata ? indata.authStatus : undefined) || 'unknown'

  const user = {
    ...defaultUser(),
    ...indata,
    roles,
    actions,
    authStatus,
    passwordExpiresSoon: willPasswordExpireSoon(indata.factors),
    ...normalizeUserParam('emails', indata.emails),
    can: (action) => actions.has(action),
    canAny: (actionList) => !actions.isDisjointFrom(new Set(actionList)),
    is: (role) => roles.has(role),
    isAny: (roleList) => !roles.isDisjointFrom(new Set(roleList)),
    isIdentified: authStatus !== 'unknown',
    isAuthN: ['authed', 'multi-authed'].includes(authStatus),
    _real: real || false
  }

  user.age = (Date.now() - new Date(user.insertedAt).getTime()) / 86400000

  Object.assign(user, normalizeUserParam('data', user.data))

  user._last = getUnixTime(parseISO(user.lastSeen))

  return user
}

export async function doVerifyEmailCode(code) {
  return fetch(`${config.app}/ev?code=${code}&redirect=false`, {
    method: 'GET',
    credentials: 'include'
  }).then((res) => res.text())
}

// TODO: Phase out b/c user.org is active org now
export function userToActiveOrgId(user) {
  return user?.settings?.activeOrgId
}
