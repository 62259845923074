// https://medium.com/javascript-scene/reduce-composing-software-fe22f0c39a1d
export const pipe = (...fns) => (x) => fns.reduce((v, f) => f(v), x)

/*
 * @doctests
 *
 * ```js
 * t.is(compose(x => x + 1, x => x / 2)(10), 6)
 * t.deepEqual(compose(x => [x, x], x => x + 3)(1), [4,4])
 * t.deepEqual(compose(x => x / 4, x => x + 1)(63), 16)
 * ```
 */
export const compose = (...fns) => (x) => fns.reduceRight((v, f) => f(v), x)

/*
 * @doctests
 *
 * ```js
 * t.is(curry(x => x + 1, 3)(), 4)
 * t.is(curry((x, y) => x + y, 10, 23)(), 33)
 * t.is(curry((x, y) => x + y, 10)(23), 33)
 * ```
 */
export const curry = (fxn, ...args) => fxn.bind(null, ...args)

/*
 * @doctests
 *
 * ```js
 * t.is(uncurry((a, b) => a + b)([1, 3]), 4)
 * t.is(uncurry((a, b) => a + b)([1, 2, 3]), 3)
 * ```
 */
export const uncurry = (fxn) => (args) => fxn.apply(null, args)

export const fork = (...fxns) => (value) =>
  Promise.all([fxns.map((fxn) => Promise.resolve(fxn(value)))])

export const inspect = (val, label = 'INSPECT') => {
  console.log(label, val)
  return val
}

/*
 * @doctests
 *
 * ```js
 * t.is(identity(4), 4)
 * ```
 */
export const identity = (x) => x

/*
 * @doctests
 *
 * ```js
 * t.is(not(x => true)(), false)
 * t.is(not(x => x === 10)(3), true)
 * ```
 */
export const not = (fxn) => (...args) => !fxn(...args)

/*
 * @doctests
 *
 * ```js
 * t.is(toggle(true), false)
 * t.is(toggle(false), true)
 * ```
 */
export const toggle = (x) => !x

/*
 * @doctests
 *
 * ```js
 * t.is(noop(), undefined)
 * t.is(noop(45), undefined)
 * ```
 */
export const noop = (...args) => {}

/*
 * @doctests
 *
 * ```js
 * t.is(constant(4)(), 4)
 * t.is(constant('cat')(), 'cat')
 * ```
 */
export const constant = (x) => () => x

/*
 * @doctests
 *
 * ```js
 * t.truthy(odd(3))
 * t.falsy(odd(2))
 * ```
 */
export const odd = (x) => x % 2 === 1
