import { freeze } from 'utils/dom'
import { joinPath } from 'utils/string'

import { paths as common, prefix } from 'common/paths'

export const paths = {
  sys: freeze({
    ...common.sys,
    admin: `${prefix}/adm/`,
    adminRoute: `${prefix}/adm/:view?/:targetId?/:tab?`,
    adminOrgs: `${prefix}/adm/orgs`,
    adminUsers: `${prefix}/adm/user`,
    adminTemplates: `${prefix}/adm/templates`,
    inventory: `${prefix}/inv`,
    inventoryRoute: `${prefix}/inv/:view?/:targetId?/:sub?/:subId?`,
    registrations: `${prefix}/reg`,
    registrationsRoute: `${prefix}/reg/:view?/:targetId?`,
    reports: `${prefix}/reports`,
    reportsRoute: `${prefix}/reports/:report?/:opt?`,
    sku: `${prefix}/sku`,
    skuRoute: `${prefix}/sku/:skuId?/:view?/:facilityId?`,
    taxonomy: `${prefix}/taxonomy`,
    taxonomyRoute: `${prefix}/taxonomy/:targetId?/:view?`
  }),
  public: freeze({
    ...common.public,
    compute: '/compute',
    computeRoute: '/compute/:targetId?/:view?/:subId?',
    // network: '/network',
    org: '/org',
    orgRoute: '/org/:targetId?/:view?/',
    returnCode: '/return',
    returnCodeRoute: '/return/:action?/:shortId?',
    start: '/start',
    startRoute: '/start/:campaign?/:location?/:skus?',
    // storage: '/storage',
    support: '/support',
    supportRoute: '/support/'
  })
}

export const profilePath = joinPath(paths.public.user, 'self', 'profile')

export function orgPath(shortId = '_', section = 'settings') {
  return joinPath(paths.public.org, shortId, section)
}

export function computePath(shortId = '_', view) {
  return joinPath(paths.public.compute, shortId, view)
}

export function sysInvPath(view, target, qs = '') {
  return joinPath(paths.sys.inventory, view, target, qs)
}

export function sysRegPath(view, target) {
  return joinPath(paths.sys.registrations, view, target)
}

export default paths
