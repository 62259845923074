function matchError({
  graphQLErrors,
  networkError,
  operation,
  forward,
  message
}) {
  return message
}

export function errorString(err) {
  let message = matchError(err)
  if (message) {
    return message
  } else {
    message = err.toString()
    if (message) {
      return message
    } else {
      return 'Unexpected error, please try again in a few minutes'
    }
  }
}

export function readableError(err) {
  const backend =
    'Unexpected response from backend, cannot continue, please try again in a few minutes'
  const message = errorString(err)
  return message
    .replace(/Network error: Unexpected token P in JSON at position 0/, backend)
    .replace(/^.*Unexpected token < in JSON .*$/, backend)
    .replace(/^.*Failed to fetch.*$/, backend)
    .replace(/^Network error: /, '')
    .replace(/Server /, 'backend ')
    .replace(/GraphQL error: /i, '')
}

/*
 * @doctests
 *
 * ```js
 * return Promise.all([
 *    catchMap(x => x + 1)(5).catch(x => t.is(6, x)),
 *    catchMap(x => x - 1)([1,2,3]).catch(x => t.deepEqual([0,1,2], x))
 *  ])
 * ```
 */
export function catchMap(fxn) {
  return (err) => Promise.reject(Array.isArray(err) ? err.map(fxn) : fxn(err))
}
