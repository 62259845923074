import { useCallback, useEffect, useRef } from 'react'

export function PassThrough({ children }) {
  return children
}

export function Nothing() {
  return null
}

export function useScrollTo(scroll) {
  const elem = useRef()
  useEffect(() => {
    if (scroll && elem.current !== undefined) {
      elem.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [elem, scroll])

  return elem
}

export function useScrollBottom(behavior = 'smooth') {
  const ref = useRef()
  const scrollBottom = useCallback(() => {
    if (ref.current != null) {
      ref.current.scrollTo({ top: ref.current.scrollHeight, left: 0, behavior })
    }
  }, [behavior])

  return { ref, scrollBottom }
}
