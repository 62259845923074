import React from 'react'
import { useLocation } from 'react-router-dom'

import Link from 'tools/Link'
import Loading from 'tools/Loading'
import { ROLES } from 'utils/user'

export const SECTIONS = {
  compute: {
    icon: 'fas fa-server',
    color: 'r11',
    desc: 'Manage Your Servers',
    label: 'Compute',
    // add labels to override the default behavior of pluralizing with adding an 's'
    labels: 'Compute'
  },
  registration: {
    icon: 'fas fa-rectangle-list',
    color: 'r7',
    role: ROLES.HUB_MANAGER,
    label: 'Registration',
    desc: 'Customer Rentals'
  },
  server: {
    icon: 'fas fa-cube',
    color: 'r5',
    label: 'Inventory',
    labels: 'Inventory',
    desc: 'Manage Inventory',
    action: 'asset_edit'
  },
  server_port: {
    icon: 'fas fa-arrow-right-to-bracket',
    label: 'Server Port',
    color: 'r5'
  },
  org: {
    icon: 'fas fa-building',
    color: 'r0',
    label: 'Org Account',
    desc: "Configure your Organization's Account"
  },
  admin_org: {
    icon: 'fas fa-city',
    color: 'r0',
    label: 'Admin Org',
    desc: 'Org Administration',
    role: ROLES.HUB_MANAGER
  },
  admin_user: {
    icon: 'fas fa-users',
    color: 'r8',
    label: 'Admin User',
    desc: 'User Administration',
    role: ROLES.HUB_MANAGER
  },
  sku: {
    icon: 'fas fa-cubes-stacked',
    label: 'SKU',
    color: 'r14',
    desc: 'Manage SKUs',
    action: 'sku_view'
  },
  report: {
    label: 'Report',
    icon: 'fas fa-chart-line',
    desc: 'Cato Administrative Reports',
    action: 'hub_report',
    color: 'r3'
  },
  taxonomy: {
    icon: 'fas fa-share-nodes',
    color: 'r3',
    label: 'Taxonomy',
    labels: 'Taxonomy',
    desc: 'Manage System Taxonomy',
    action: 'taxonomy_view'
  },
  elevation: {
    icon: 'fas fa-table-cells fa-rotate-90',
    color: 'r6',
    label: 'Rack Elevations',
    labels: 'Rack Elevations',
    desc: 'Server rack elevation visualizations',
    action: 'taxonomy_view'
  }
}

// Fugly but it helps keep things uniform
export const LOCATION_BUTTON_CLASSES = 'button clear flex-items'
export function LocationButtonLabel({ children }) {
  return (
    <>
      <span className="mr1 pb1 fw1 f1">{children}</span>
      <i className="fas fa-right-from-bracket" />
    </>
  )
}

export function SectionLocationButton({
  alt = <div className="font-mono">—</div>,
  server,
  children
}) {
  if (!server) return alt
  return (
    <div className="flex-items gh2 f4">
      <div className="font-mono f5 b">{server.location}</div>
      {children}
    </div>
  )
}

export function SectionHeading({
  className = '',
  section,
  sectionLabel,
  loading = false,
  children,
  backTo,
  boundary = true
}) {
  const sec = SECTIONS[section]
  const { search } = useLocation()
  return (
    <div>
      <div className="hmin3 flex items-end">
        <div className={`f6 mr2 ${sec.color} mb1`}>
          <i className={`${sec.icon} mr2`} />
          <span className="fw1">
            {sec.labels || sec.label}
            {sectionLabel ? ': ' : !sec.labels && 's'}
          </span>
        </div>
        {sectionLabel}
        {loading && (
          <div className="f3 mb2">
            <Loading babble={false} wait={0} />
          </div>
        )}
        <div className="ml-auto flex-items">
          {children}
          {backTo && (
            <Link
              type="button"
              className="clear ml1"
              to={backTo.includes('?') ? backTo : backTo + search}
            >
              <i className="fas fa-angles-up minimize" />
            </Link>
          )}
        </div>
      </div>
      {boundary && <SectionBoundary color={sec.color} />}
    </div>
  )
}

export function SectionBoundary({ color }) {
  return <div className={`bg-${color} mt2`} style={{ height: '0.5rem' }} />
}
