import React from 'react'

import style from './Activity.module.scss'

function Activity({ show = false, offset = false }) {
  if (show)
    return <div className={`${style.activity} ${offset ? style.offset : ''}`} />
  return null
}

export default Activity
