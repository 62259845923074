import { dedupe } from './array.js'
import { getIn } from './dict.js'

/*
 * @doctests
 *  ```js
 *  const goodResponses = [
 *    { "a" : { "b": { result: 1, success: true } } },
 *    { "a" : { "b": { result: 2, success: true } } },
 *    { "a" : { "b": { result: 3, success: true } } },
 *  ]
 *  const badResponses = [
 *    { "a" : { "b": { result: 1, success: true } } },
 *    { "a" : { "b": { success: false, reason: "insufficient pylons" } } },
 *    { "a" : { "b": { result: 3, success: true } } },
 *    { "a" : { "b": { success: false, reason: "server spite" } } },
 *  ]
 *  t.plan(2)
 *  const r1 = handleManyResponses("a", "b")(goodResponses)
 *    .then((result) => {
 *      t.deepEqual(result, [1, 2, 3])
 *  })
 *  const r2 = handleManyResponses("a", "b")(badResponses)
 *    .catch((err) => {
 *      t.deepEqual(err, ["insufficient pylons", "server spite"])
 *  })
 *  return Promise.all([r1, r2])
 *  ```
 */
export const handleManyResponses = (...path) => (manyResponses) => {
  const responses = manyResponses.map((response) => getIn(response, path))
  const failures = responses.filter((resp) => !resp?.success)

  if (failures.length === 0) {
    return Promise.resolve(
      responses.map(({ result, results }) => result ?? results)
    )
  }
  const failureReasons = dedupe(
    failures.map((resp) => resp?.reason?.toString() ?? 'something went wrong')
  )
  return Promise.reject(failureReasons)
}

/*
 * @doctests
 *  ```js
 *  const response = { "data" : { "adiXUpsert": { result: {hello: "world"}, success: true, reason: undefined } } }
 *  const response2 = { "a" : { "b": { result: undefined, success: false, reason: "morally deficient"} } }
 *  t.plan(3)
 *  const r1 = handleOneResponse("data", "adiXUpsert")(response)
 *    .then((result) => {
 *      t.deepEqual(result, {hello: "world"})
 *  })
 *  const r2 = handleOneResponse("data", "adiXUpsertMisspelled")(response)
 *    .catch((err) => {
 *      t.is(err, 'something went wrong')
 *  })
 *  const r3 = handleOneResponse("a", "b")(response2)
 *    .catch((err) => {
 *      t.is(err, 'morally deficient')
 *  })
 *  return Promise.all([r1, r2, r3])
 *  ```
 */
export const handleOneResponse = (...path) => (response) =>
  Promise.resolve(handleManyResponses(...path)([response]))
    .then(([result]) => result)
    .catch(([err]) => Promise.reject(err))

export const handleResponse = (...path) => (response) => {
  if (Array.isArray(response)) {
    return handleManyResponses(...path)(response)
  } else {
    return handleOneResponse(...path)(response)
  }
}
